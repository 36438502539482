import {Box, Tooltip} from 'components/mui';
import InfoFilledIcon from '@mui/icons-material/Info';

const InfoIconTooltip = ({
    id,
    message,
    color = 'info',
    size = 'medium',
}: {
    id?: string;
    message: string;
    size?: 'small' | 'medium' | 'large';
    color?:
        | 'inherit'
        | 'action'
        | 'disabled'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
}) => {
    return (
        <Tooltip title={message} id={id}>
            <Box display="flex" alignSelf="self-end">
                <InfoFilledIcon color={color} fontSize={size} />
            </Box>
        </Tooltip>
    );
};

export default InfoIconTooltip;
