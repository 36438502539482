import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import {
    Card,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Box,
    Tooltip,
    Typography,
    LinearProgress,
} from 'components/mui';

import {
    Control,
    Controller,
    FormState,
    UseFormClearErrors,
    UseFormSetValue,
} from 'react-hook-form';
import {SetStateAction, SyntheticEvent} from 'react';

interface SignalSearchInputProps {
    formState: FormState<{
        signalDescriptionSearch: string;
    }>;
    control: Control<{
        signalDescriptionSearch: string;
    }>;
    setValue: UseFormSetValue<{
        signalDescriptionSearch: string;
    }>;
    handleOnSubmit: (e: SyntheticEvent) => void;
    clearErrors: UseFormClearErrors<{
        signalDescriptionSearch: string;
    }>;
    setSignalTypeDescription: (value: SetStateAction<string>) => void;
    loading: boolean;
}

const ExploreSignalInput = ({
    handleOnSubmit,
    control,
    formState,
    setValue,
    clearErrors,
    setSignalTypeDescription,
    loading,
}: SignalSearchInputProps) => {
    const {errors, isValid} = formState;

    return (
        <Grid item xs={12} sx={{transition: 'all 0.3s ease-in-out'}} gap={1}>
            <Card
                sx={{
                    m: 1,
                }}
                elevation={1}
            >
                <Box
                    display={'flex'}
                    alignContent={'center'}
                    alignItems={'center'}
                >
                    <Box flexGrow={1} pr={2}>
                        <form onSubmit={handleOnSubmit}>
                            <Controller
                                name="signalDescriptionSearch"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        data-testid="signal-search"
                                        fullWidth
                                        label="Explore more"
                                        autoFocus
                                        multiline
                                        sx={{
                                            m: 1,
                                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-input':
                                                {
                                                    color: 'initial',
                                                },
                                        }}
                                        autoComplete="off"
                                        placeholder="Enter a description of the companies to start generating relevant signals "
                                        error={Boolean(
                                            errors?.signalDescriptionSearch
                                                ?.message
                                        )}
                                        helperText={
                                            errors?.signalDescriptionSearch
                                                ?.message
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={
                                                            <Typography>
                                                                Clear
                                                            </Typography>
                                                        }
                                                        placement="top-start"
                                                    >
                                                        <IconButton
                                                            data-testid="ClearIcon"
                                                            onClick={() => {
                                                                setValue(
                                                                    'signalDescriptionSearch',
                                                                    ''
                                                                );
                                                                setSignalTypeDescription(
                                                                    ''
                                                                );
                                                                clearErrors();
                                                            }}
                                                            edge="end"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={
                                                            <Typography>
                                                                Generate with AI
                                                            </Typography>
                                                        }
                                                        placement="top-start"
                                                    >
                                                        <Box component="span">
                                                            <IconButton
                                                                data-testid="GenerateWithAI"
                                                                aria-label={
                                                                    'submit search'
                                                                }
                                                                disabled={
                                                                    !isValid
                                                                }
                                                                edge="end"
                                                                type="submit"
                                                            >
                                                                <AutoFixHighIcon
                                                                    sx={{m: 1}}
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </form>
                    </Box>
                </Box>
                {loading && <LinearProgress />}
            </Card>
        </Grid>
    );
};

export {ExploreSignalInput};
