import {PrivatePage} from 'components/ui/page/PrivatePage';
import {SubPage} from 'components/ui/page/SubPage';
import useNavigationTabs from 'components/hooks/useNavigationTabs';
import type {NavigationTab} from 'components/types';

import {
    PAGE_ID_EXPLORE_SIGNALS,
    PAGE_ID_SIGNAL_CHAT,
    PAGE_ID_SIGNALS,
} from 'components/util/constants';

export const signalsGenerateNavigationTabs: NavigationTab[] = [
    {
        pageId: PAGE_ID_EXPLORE_SIGNALS,
        label: 'Explore Signals',
        route: 'explore',
    },
    {
        pageId: PAGE_ID_SIGNAL_CHAT,
        label: 'Signal Chatbot',
        route: 'chat',
    },
    {
        pageId: PAGE_ID_SIGNALS,
        label: 'Saved Signals',
        route: '',
    },
];

function SignalPage({
    pageId,
    title,
    children,
    headerContent,
    useFixedHeader = false,
}: Readonly<{
    pageId: string;
    title: string;
    children: React.ReactNode;
    headerContent?: React.ReactNode | (() => React.ReactNode);
    useFixedHeader?: boolean;
}>) {
    const {navigationTabs, currentPageNavigationTab} = useNavigationTabs(
        signalsGenerateNavigationTabs,
        pageId
    );
    return (
        <PrivatePage pageId={pageId} title={title}>
            <SubPage
                title={title}
                navigationTabsBaseUrl="/signals"
                navigationTabs={navigationTabs}
                currentPageNavigationTab={currentPageNavigationTab}
                headerContent={headerContent}
                useFixedHeader={useFixedHeader}
            >
                {children}
            </SubPage>
        </PrivatePage>
    );
}

export {SignalPage};
