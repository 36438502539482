import {Paper, SxProps, Typography} from 'components/mui';
import styled from 'styled-components';

const CodeBlockStyles = styled(Paper)`
    padding: ${({theme}) => theme.spacing(2)};
    white-space: pre-wrap;
    max-width: 100%;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4rem;
    line-height: 1.5;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 4px;
    margin-bottom: ${({theme}) => theme.spacing(1)};
`;

const CodeStyles = styled(Typography)`
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4rem;
    line-height: 1.5;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 4px;
    padding: ${({theme}) => theme.spacing(1)};
` as typeof Typography;

export const CodeBlock = ({children, sx}: {children: string; sx?: SxProps}) => {
    return (
        <CodeBlockStyles sx={sx} elevation={0}>
            {children}
        </CodeBlockStyles>
    );
};

export const Code = ({children}: {children: string}) => (
    <CodeStyles component="span">{children}</CodeStyles>
);
