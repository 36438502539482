import type {NavigationTab} from 'components/types';

export interface NavigationTabData {
    navigationTabs: NavigationTab[];
    currentPageNavigationTab?: NavigationTab;
}

const useNavigationTabs = (
    navigationTabs: NavigationTab[],
    currentPagePageId?: string
): NavigationTabData => {
    const currentPageNavigationTab: NavigationTab = currentPagePageId
        ? navigationTabs.find(
              ({pageId: tabPageId}) => currentPagePageId === tabPageId
          )
        : {
              pageId: '',
              label: '',
              route: '',
          };

    return {
        currentPageNavigationTab,
        navigationTabs,
    };
};

export default useNavigationTabs;
