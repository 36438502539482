import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {logger} from 'utils/logger';

const IS_SERVER = typeof window === 'undefined';

const getValue = <T>(value?: T | (() => T)): T => {
    return value instanceof Function ? value() : value;
};

interface UseLocalStorageOptions<T> {
    serializer?: (value: T) => string;
    deserializer?: (value: string) => T;
}

const useLocalStorage = <T>(
    userSettingKey: string,
    initialValue: T | (() => T),
    options: UseLocalStorageOptions<T> = {}
): [T, Dispatch<SetStateAction<T>>] => {
    const serializer = useCallback(
        (data: T): string => {
            if (options?.serializer) {
                return options.serializer(data);
            }

            return JSON.stringify(data);
        },
        [options]
    );

    const deserializer = useCallback(
        (rawData: string): T => {
            if (options?.deserializer) {
                return options.deserializer(rawData);
            }

            if (rawData === 'undefined') {
                return undefined;
            }

            const defaultValue: T = getValue(initialValue);

            let parsedData: T;

            try {
                parsedData = JSON.parse(rawData);
            } catch (error) {
                logger.error('Error parsing data as JSON:', error);
                parsedData = defaultValue;
            }

            return parsedData;
        },
        [options, initialValue]
    );

    const readLocalStorageValue = useCallback((): T => {
        const defaultValue: T = getValue(initialValue);

        if (IS_SERVER) {
            return defaultValue;
        }

        try {
            const rawData = window.localStorage.getItem(userSettingKey);

            return rawData ? deserializer(rawData) : defaultValue;
        } catch (error) {
            logger.warn(
                `Error reading local storage key \`${userSettingKey}\`.`
            );

            return defaultValue;
        }
    }, [userSettingKey, initialValue, deserializer]);

    const [localValue, setLocalValue] = useState(readLocalStorageValue);

    const setValue = useCallback<Dispatch<SetStateAction<T>>>(
        (value) => {
            if (IS_SERVER) {
                logger.warn(
                    `Tried setting local storage key \`${userSettingKey}\` in wrong environment.`
                );
            }

            try {
                const newValue =
                    value instanceof Function
                        ? value(readLocalStorageValue())
                        : value;

                window.localStorage.setItem(
                    userSettingKey,
                    serializer(newValue)
                );

                setLocalValue(newValue);
            } catch (error) {
                logger.warn(
                    `Error setting local storage key \`${userSettingKey}\`:`,
                    error
                );
            }
        },
        [readLocalStorageValue, userSettingKey, serializer, setLocalValue]
    );

    useEffect(() => {
        setLocalValue(readLocalStorageValue);
    }, [userSettingKey]);

    return [localValue, setValue];
};

export {useLocalStorage};
