import {useUserContext} from 'components/contexts/user';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    Grow,
    Card,
} from 'components/mui';

import {
    useClientOrganizationCachedSignalsQuery,
    MAXIMUM_SIGNALS,
} from 'components/ui/pages/signals/explore/hooks/useClientOrganizationCachedSignalsQuery';
import {RecommendedSignalCard} from 'components/ui/pages/signals/explore/RecommendedSignalCard';
import {ExploreSignalInput} from 'components/ui/pages/signals/explore/ExploreSignalInput';
import {useExploreSignalsPageContent} from './hooks/useExploreSignalsPageContent';
import {useEffect, useMemo} from 'react';
import {useLocalStorage} from 'components/hooks/useLocalStorage';

function SignalsLoading({loading}: Readonly<{loading: boolean}>) {
    if (loading) {
        return (
            <Card
                sx={{
                    m: 1,
                    transition: 'all 0.3s ease-in-out',
                }}
                elevation={1}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    data-testid="signals-loading"
                >
                    <Box
                        p={4}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <CircularProgress size={24} />
                        <Box mt={2}>
                            <Typography variant="body1">
                                Generating a New Signal
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        );
    }
    return null;
}
export const SIGNAL_TYPE_DESCRIPTION_KEY = 'signalTypeDescription';

function ExploreSignalsPageContent() {
    const user = useUserContext();
    const clientOrganizationId = user.clientOrganization.id;

    const [signalTypeDescription, setSignalTypeDescription] =
        useLocalStorage<string>(SIGNAL_TYPE_DESCRIPTION_KEY, '');

    const {signals, loading, setSessionCachedSignal, deleteAllCachedSignals} =
        useClientOrganizationCachedSignalsQuery(
            clientOrganizationId,
            user,
            signalTypeDescription
        );

    const {control, formState, setValue, clearErrors, handleOnSubmit} =
        useExploreSignalsPageContent({
            user,
            deleteAllCachedSignals,
            signalTypeDescription,
            setSignalTypeDescription,
        });

    const sortedSignals = useMemo(() => {
        if (signals.length === 0) {
            return [];
        }
        return [signals[0], ...signals.filter((_, index) => index !== 0)];
    }, [signals]);

    useEffect(() => {
        setSessionCachedSignal(null);
    }, []);

    return (
        <Grid container>
            <ExploreSignalInput
                control={control}
                formState={formState}
                setValue={setValue}
                handleOnSubmit={handleOnSubmit}
                clearErrors={clearErrors}
                setSignalTypeDescription={setSignalTypeDescription}
                loading={loading}
            />

            {sortedSignals.map((signal, index) => (
                <Grow
                    key={signal.id}
                    in={true}
                    timeout={300}
                    unmountOnExit
                    style={{
                        transitionDelay: `${index * 25}ms`,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        sx={{transition: 'all 0.3s ease-in-out'}}
                        gap={1}
                    >
                        <RecommendedSignalCard
                            cachedSignal={signal}
                            signalCacheIndex={index}
                            clientOrganizationId={clientOrganizationId}
                            setSessionCachedSignal={setSessionCachedSignal}
                        />
                    </Grid>
                </Grow>
            ))}

            {(user.clientOrganization.hasContextProfileAnswers ||
                signalTypeDescription) &&
                signals.length < MAXIMUM_SIGNALS && (
                    <Grow in={loading} timeout={300} unmountOnExit>
                        <Grid item xs={12} md={6} lg={4}>
                            <SignalsLoading loading={loading} />
                        </Grid>
                    </Grow>
                )}
        </Grid>
    );
}

export {ExploreSignalsPageContent};
