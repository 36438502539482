import {Box, Typography, CircularProgress, Button} from 'components/mui';
import {CodeBlock} from 'components/ui/formatting/CodeBlock';
import {SignalComponentUnion} from 'src/types/graphql-types';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIconTooltip from 'components/ui/tooltips/InfoIconTooltip';

export const SignalCardSaveButtonContent = ({
    loading,
    success,
    error,
}: {
    loading: boolean;
    success: boolean;
    error: boolean;
}) => {
    if (loading) {
        return (
            <Box component="span" data-testid="save-button-loading">
                <CircularProgress size={24} />
            </Box>
        );
    }

    if (success) {
        return (
            <>
                <CheckIcon /> Saved
            </>
        );
    }
    if (error) {
        return (
            <>
                <ErrorIcon /> Error
            </>
        );
    }
    return <>Save</>;
};

export const SignalCardSaveButton = ({
    loading,
    success,
    error,
    onClick,
}: {
    loading: boolean;
    success: boolean;
    error: boolean;
    onClick: () => void;
}) => {
    const isDisabled = success || error || loading;
    let color: 'primary' | 'success' | 'error' = 'primary';
    if (success) {
        color = 'success';
    } else if (error) {
        color = 'error';
    }
    return (
        <Button
            disabled={isDisabled}
            color={color}
            onClick={onClick}
            data-testid={`save-button-${color}`}
        >
            <SignalCardSaveButtonContent
                loading={loading}
                success={success}
                error={error}
            />
        </Button>
    );
};

export const SignalComponentUi = ({
    component,
}: {
    component: SignalComponentUnion;
}) => {
    return (
        <Box>
            <Box position="relative" display="flex" flexDirection="column">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start"
                    data-testid="signal-card-accordion"
                >
                    <Box width="100%">
                        <CodeBlock>{component.queryText}</CodeBlock>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        width={'100%'}
                    >
                        <Typography color="info.main" variant="body2">
                            Why was this chosen?{' '}
                        </Typography>
                        <Box ml={1}>
                            <InfoIconTooltip message={component.reason} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
