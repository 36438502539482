import {ExploreSignalsPageContent} from 'components/ui/pages/signals/explore/ExploreSignalsPageContent';
import {PAGE_ID_EXPLORE_SIGNALS} from 'components/util/constants';
import {SignalPage} from 'components/ui/page/SignalPage';
import {LinkButton} from 'components/ui/links/Link';

function ExploreSignalsPage() {
    return (
        <SignalPage
            title="Explore Signals"
            pageId={PAGE_ID_EXPLORE_SIGNALS}
            headerContent={
                <LinkButton sx={{marginLeft: 1}} href="/signals/new">
                    Create New Signal
                </LinkButton>
            }
        >
            <ExploreSignalsPageContent />
        </SignalPage>
    );
}

export default ExploreSignalsPage;
