import {yupResolver} from '@hookform/resolvers/yup';

import {trackEventWithMixPanel} from 'components/tracking/useTracking';
import {Notification} from 'core/Notification';
import User from 'core/User';
import {
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    useCallback,
    useEffect,
} from 'react';
import {useForm} from 'react-hook-form';
import {DeleteCachedSignalPayload} from 'src/types/graphql-types';
import {logger} from 'utils/logger';
import * as yup from 'yup';
const MIN_TEXT_LENGTH = 20;
const NO_CONTEXT_INFO_MESSAGE =
    'There is no context to search signals. Please enter a description of the companies to start to generate signals.';

const schema = yup.object().shape({
    signalDescriptionSearch: yup
        .string()
        .min(
            MIN_TEXT_LENGTH,
            `Search must be at least ${MIN_TEXT_LENGTH} characters`
        )
        .nullable()
        .transform((value: string) => (value === '' ? null : value)),
});

const useExploreSignalsPageContent = ({
    user,
    deleteAllCachedSignals,
    setSignalTypeDescription,
    signalTypeDescription,
}: {
    user: User;
    deleteAllCachedSignals: () => Promise<{
        deleteAllCachedSignals: DeleteCachedSignalPayload;
    }>;
    setSignalTypeDescription: Dispatch<SetStateAction<string>>;
    signalTypeDescription: string;
}) => {
    const {control, formState, setValue, handleSubmit, clearErrors} = useForm<{
        signalDescriptionSearch: string;
    }>({
        resolver: yupResolver(schema),
        defaultValues: {signalDescriptionSearch: ''},
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (signalTypeDescription) {
            setValue('signalDescriptionSearch', signalTypeDescription);
        }
    }, []);

    const handleOnRefreshSignals = useCallback(() => {
        deleteAllCachedSignals().catch((e) => {
            logger.error(e);
        });
        if (
            !(
                user?.clientOrganization?.hasContextProfileAnswers ||
                signalTypeDescription
            )
        ) {
            Notification.info(NO_CONTEXT_INFO_MESSAGE, {removeAfter: 5});
        }
    }, [
        user.clientOrganization.hasContextProfileAnswers,
        signalTypeDescription,
    ]);

    const handleOnSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        handleSubmit((data) => {
            setSignalTypeDescription(data.signalDescriptionSearch);
            deleteAllCachedSignals()
                .then(() => {
                    if (
                        !(
                            user?.clientOrganization
                                ?.hasContextProfileAnswers ||
                            signalTypeDescription
                        )
                    ) {
                        Notification.info(NO_CONTEXT_INFO_MESSAGE, {
                            removeAfter: 5,
                        });
                    }
                })
                .catch(() => null);
            trackEventWithMixPanel('signal-recommendation-search', {
                searchText: data.signalDescriptionSearch,
            });
        })(e).catch(() => null);
    };

    return {
        control,
        formState,
        setValue,
        handleSubmit,
        clearErrors,
        handleOnRefreshSignals,
        handleOnSubmit,
    };
};

export {useExploreSignalsPageContent};
